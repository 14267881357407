<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header bg-light pt-2 pb-2">
          <h5>Liste des opérations effectuées sur le compte</h5>
          <router-link
            :to="{ name: 'ticket.detail', params: { ...$route.params } }"
            class="text-dark float-right shadow-1 p-1"
          >
            <span class="fas fa-arrow-left m-r-5" /> Retourner au ticket
          </router-link>
        </div>
        <div class="card-body pl-1 pr-1 pt-2">
          <BaseLoader v-if="loading" />
          <template v-else>
            <div class="table-responsive" v-if="showMovementList">
              <table class="table table-hover" id="movement-table">
                <thead>
                  <tr>
                    <th style="width: 30px" class="text-center">Opération</th>
                    <th class="text-left" style="width: 50px">
                      Description
                    </th>
                    <th class="text-right" style="width: 30px">Montant</th>
                    <th class="text-right" style="width: 30px">
                      Solde après opération
                    </th>
                    <th style="width: 40px" class="text-center">
                      Date
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <MovementTableLine
                    v-for="(movement, index) in movements"
                    :key="index"
                    :movement="movement"
                  />
                </tbody>
              </table>
            </div>
            <div class="row justify-content-center btn-group-sm mt-3 mt-sm-0">
              <button
                @click.prevent="reloadMovementList(false)"
                class="btn btn-outline-secondary"
                :disabled="lastMetaMovementRequest.next_page_url === null"
              >
                <span class="fas fa-redo m-r-5"></span> Charger plus de
                mouvements
              </button>
            </div>

            <BaseEmptyList v-if="!showMovementList" :model="listModel" />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import MovementTableLine from "../../components/MovementTableLine";
import Vue from "vue";
import { emptyListModel } from "../../helpers/constants";

export default {
  name: "MovementList",
  components: { MovementTableLine },

  data() {
    return {
      loading: true
    };
  },

  computed: {
    ...mapState("user", ["lastMetaMovementRequest"]),
    ...mapGetters("user", ["getMovementList"]),
    movements() {
      return this.getMovementList();
    },
    listModel() {
      return emptyListModel.movement;
    },
    showMovementList() {
      return this.movements.length > 0;
    }
  },

  mounted() {
    // eslint-disable-next-line no-undef
    (async () => {
      await Vue.loadScript(
        `${window.location.origin}/assets/plugins/data-tables/js/datatables.min.js`
      );
    })().then(() => this.reloadMovementList(true));
  },

  methods: {
    reloadMovementList(start = false) {
      if (
        start ||
        (this.lastMetaMovementRequest &&
          this.lastMetaMovementRequest.next_page_url)
      ) {
        this.loading = true;
        this.$store
          .dispatch("user/getMovements", {
            page: start ? 1 : this.lastMetaMovementRequest.current_page + 1,
            users_id: this.$route.params.users_id
          })
          .then(() => {
            this.loading = false;
            setTimeout(() => this.initMovementDatable(), 50);
          });
      }
    },

    initMovementDatable() {
      window.$("#movement-table").DataTable({
        dom: "Bfrtp",
        ordering: false,
        buttons: [
          {
            extend: "pdf",
            title: "Historique des opérations de votre compte BeWallet",
            messageBottom: "Rapport généré le " + new Date().toLocaleString()
          }
        ]
      });
    }
  }
};
</script>

<style>
.page-item.active .page-link {
  background-color: #32849c !important;
}
</style>
