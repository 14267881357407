<template>
  <div>
    <BaseLoader v-if="loading" />
    <template v-else>
      <div class="card rides-bar mb-2">
        <div class="card-block custom-bg">
          <div class="row d-flex align-items-center">
            <div class="col-auto pl-0 pr-1">
              <i
                class="feather icon-shopping-cart f-30 text-white rides-icon"
              ></i>
            </div>
            <div class="col border-right text-info border-info">
              <h4 class="f-w-600 border-bottom text-info">
                {{ userStat.balance }}
                <sub><small>CFA</small></sub>
              </h4>
              <span class="d-block f-w-300">Solde actuel</span>
            </div>
            <div class="col border-right">
              <h4 class="f-w-500 border-bottom">
                {{ userStat.deposit }}
                <sub><small>CFA</small></sub>
              </h4>
              <span class="d-block f-w-300">Dépôt</span>
            </div>
            <div class="col border-right">
              <h4 class="f-w-500 border-bottom">
                {{ userStat.withdraw }}
                <sub><small>CFA</small></sub>
              </h4>
              <span class="d-block f-w-300">Retrait</span>
            </div>
            <div class="col border-right">
              <h4 class="f-w-500 border-bottom">
                {{ userStat.shopping }}
                <sub><small>CFA</small></sub>
              </h4>
              <span class="d-block f-w-300">Services</span>
            </div>
            <div class="col border-right">
              <h4 class="f-w-500 border-bottom">
                {{ userStat.payment }}
                <sub><small>CFA</small></sub>
              </h4>
              <span class="d-block f-w-300">Paiement</span>
            </div>
            <div class="col border-right">
              <h4 class="f-w-500 border-bottom">
                {{ userStat.transfer_receive }}
                <sub><small>CFA</small></sub>
              </h4>
              <span class="d-block f-w-300">T. Réçu</span>
            </div>
            <div class="col">
              <h4 class="f-w-500 border-bottom">
                {{ userStat.transfer_send }}
                <sub><small>CFA</small></sub>
              </h4>
              <span class="d-block f-w-300">T. Envoyé</span>
            </div>
          </div>
        </div>
      </div>
      <MovementList />
    </template>
  </div>
</template>

<script>
import MovementList from "../../components/profile/MovementList";
import { mapGetters } from "vuex";
export default {
  name: "UserMovements",
  components: { MovementList },
  data() {
    return {
      loading: true
    };
  },
  computed: {
    ...mapGetters("user", ["userStat"])
  },
  created() {
    this.$store
      .dispatch("user/getViewUser", this.$route.params.users_id)
      .then(() => {
        this.loading = false;
      })
      .catch(() => {
        this.$router.back();
      });
  }
};
</script>

<style scoped>
.rides-bar i.rides-icon {
  background: linear-gradient(-135deg, #32849c 0%, #3f4d67 100%);
  border-radius: 50px;
  padding: 15px;
}
.custom-bg {
  background: linear-gradient(
    -135deg,
    rgba(63, 77, 103, 0.12) 0%,
    rgba(50, 132, 156, 0.11) 100%
  );
}
</style>
